<template>
  <div class="bg-white rounded-lg shadow md:mt-4 mt-2 overflow-x-auto">
    <table class="min-w-full leading-normal">
      <thead>
        <tr>
          <th
            v-for="column in columns"
            :key="column"
            class="md:text-md sm:text-sm text-xs md:px-5 px-2 md:py-3 py-1 border-b-2 border-gray-200 bg-gray-100 text-start font-semibold text-gray-600 uppercase tracking-wider"
          >
            {{ columnDisplayNames[column] || column }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="row in tableData" :key="row.id">
          <td
            v-for="column in columns"
            :key="column"
            class="md:text-md sm:text-sm text-xs md:px-5 px-2 md:py-5 py-2 border-b border-gray-200 bg-white text-start"
          >
            {{ row[column] }}
          </td>
        </tr>
      </tbody>
    </table>
    <!-- Pagination Element -->
    <pagination-element
      :total-items="totalItems"
      :page-size="pageSize"
      :current-page="currentPage"
      @page-change="handlePageChange"
    />
  </div>
</template>

<script setup>
import { ref, onMounted, watch } from 'vue';
import axios from 'axios';
import PaginationElement from './PaginationElement.vue';

const props = defineProps({
  fetchString: {
    type: String,
    required: true
  },
  pageSize: {
    type: Number,
    default: 10
  }
});

const columns = ref([]);
const tableData = ref([]);
const totalItems = ref(0);
const currentPage = ref(1);

const API_URL = process.env.VUE_APP_API_URL;

// Define a mapping from actual column names to display names
const columnDisplayNames = {
  event: 'Suggested Parts',
  route: 'Route', // You can add more mappings as needed
};

async function fetchData() {
  try {
    const url = `${API_URL}${props.fetchString}&page=${currentPage.value}&page_size=${props.pageSize}`;
    const response = await axios.get(url);
    tableData.value = response.data.results;
    columns.value = tableData.value.length > 0 ? Object.keys(tableData.value[0]) : [];
    totalItems.value = response.data.total_items;
  } catch (error) {
    console.error('Error fetching data:', error);
    tableData.value = [];
    totalItems.value = 0;
  }
}

// Handle page change and emit the event
const handlePageChange = (page) => {
  currentPage.value = page;
  fetchData();
};

// Reset currentPage and fetch data when fetchString changes
watch(() => props.fetchString, () => {
  currentPage.value = 1; // Reset the current page to 1
  fetchData();
});

// Fetch data when component is mounted
onMounted(fetchData);
</script>

<style scoped>
/* Additional Tailwind CSS styles if needed */
</style>
