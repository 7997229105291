<template>
  <div class="flex items-center justify-center min-h-screen bg-gray-100">
    <div class="w-full max-w-md p-8 space-y-6 bg-white shadow-md rounded-lg">
      <h2 class="text-2xl font-bold text-center">Register</h2>
      <form @submit.prevent="handleSubmit" class="space-y-6">
        <div class="flex flex-row space-x-1">
          <div>
            <input
              type="text"
              id="firstName"
              placeholder="First name"
              v-model="firstName"
              required
              class="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
            />
          </div>
          <div>
            <input
              type="text"
              id="lastName"
              placeholder="Last name"
              v-model="lastName"
              required
              class="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
            />
          </div>
        </div>
        <div class="flex flex-col space-y-2">
          <div>
            <input
              type="email"
              id="email"
              placeholder="Email address"
              v-model="username"
              required
              class="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
            />
          </div>
          <div>
            <input
              type="password"
              id="password1"
              placeholder="Password"
              v-model="password1"
              required
              class="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
            />
          </div>
          <div>
            <input
              type="password"
              id="password2"
              placeholder="Confirm Password"
              v-model="password2"
              required
              class="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
            />
          </div>
        </div>
        <div class="flex flex-col justify-start">
          <input
            type="tel"
            id="phone"
            placeholder="Phone number"
            v-model="phone"
            name="phone"
            required
            pattern="[0-9]{8,15}$"
            autocomplete="off"
            class="w-1/2 px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500">
        </div>
        <div v-if="errorMessage" class="text-red-500 text-center">
          {{ errorMessage }}
        </div>
        <div>
          <button
            type="submit"
            class="w-full px-4 py-2 text-white bg-indigo-600 rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Register
          </button>
        </div>

        <div>
          <p class="text-sm text-center text-gray-600">
            Already have an account? <a href="/login" class="text-indigo-600 hover:underline">Login</a>
          </p>
        </div>
      </form>
    </div>

    <!-- Modal -->
    <div v-if="isModalVisible" class="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75">
      <div class="bg-white p-6 rounded-lg shadow-lg">
        <h2 class="text-2xl font-bold mb-4">Registration Successful</h2>
        <p class="mb-4">Please verify your account by clicking the link in the email we sent you.</p>
        <button @click="handleModalButtonClick" class="w-full px-4 py-2 text-white bg-indigo-600 rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
          Email Verified / Log in now
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import { register } from '../services/auth';
import { useRouter } from 'vue-router';

const router = useRouter();

const firstName = ref('');
const lastName = ref('');
const username = ref('');
const password1 = ref('');
const password2 = ref('');
const phone = ref('');
const isModalVisible = ref(false);
const errorMessage = ref('');

const handleSubmit = async () => {
  errorMessage.value = ''; // Clear any previous error message

  if (password1.value !== password2.value) {
    errorMessage.value = 'Passwords do not match';
    return;
  }

  try {
    const response = await register(firstName.value, lastName.value, username.value, password1.value, password2.value, phone.value);
    console.log('Registration successful:', response.data);
    isModalVisible.value = true;
  } catch (error) {
    console.error('Registration failed:', error.response.data);
    if (error.response.data.email) {
      errorMessage.value = error.response.data.email[0];
    } else {
      errorMessage.value = 'Registration failed. Please try again.';
    }
  }
};

const handleModalButtonClick = () => {
  router.push({ name: 'LoginPage' });
  isModalVisible.value = false;
};
</script>

<style scoped>
/* Add any additional custom styles if needed */
</style>
