<template>
  <div class="flex items-center justify-center min-h-screen bg-gray-100">
    <div class="w-full max-w-md p-8 space-y-6 bg-white shadow-md rounded-lg">
      <h2 class="text-2xl font-bold text-center">Reset Password</h2>
      <form @submit.prevent="resetPassword" class="space-y-6">
        <div class="flex flex-col space-y-2">
          <div>
            <input
              type="hidden"
              v-model="token"
            />
          </div>
          <div>
            <input
              type="password"
              placeholder="Enter new password"
              v-model="password"
              required
              class="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
            />
          </div>
        </div>
        <div v-if="errorMessage" class="text-red-500 text-center">
          {{ errorMessage }}
        </div>
        <div>
          <button
            type="submit"
            class="w-full px-4 py-2 text-white bg-indigo-600 rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Reset Password
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import axios from 'axios';
import { useRouter, useRoute } from 'vue-router';

const password = ref('');
const route = useRoute();
const router = useRouter();
const errorMessage = ref('');

const resetPassword = async () => {
  errorMessage.value = '';
  const token = route.query.token;
  try {
    const API_URL = process.env.VUE_APP_API_URL;
    await axios.post(API_URL + 'api/password_reset/confirm/', {
      token: token,
      password: password.value
    });
    alert('Password has been reset successfully!');
    router.push('/login');
  } catch (error) {
    console.error('Error resetting password:', error);
    if (error.response && error.response.data && error.response.data.error) {
      errorMessage.value = error.response.data.error;
    } else {
      errorMessage.value = 'There was an error. Please try again.';
    }
  }
};
</script>

<style scoped>
/* Add any additional custom styles if needed */
</style>
