<template>
  <div class="w-full mx-auto justify-between flex flex-col p-8">
    <h1 class="text-4xl font-thin text-center text-start my-6">Alert Page</h1>
    <div class="flex flex-row justify-between mt-3">
          <div class="w-1/3 bg-white p-4 rounded-lg shadow">
      <h1 class="text-2xl font-bold text-center">Set Alert</h1>
      <form @submit.prevent="setAlert">
      <div class="mb-4">
        <label class="block text-sm font-bold mb-2">Select Device Type</label>
        <select v-model="newAlert.deviceType" @change="fetchDevices" class="w-full p-2 border rounded">
          <option v-for="type in deviceTypes" :key="type.id" :value="type.id">{{ type.name }}</option>
        </select>
      </div>


      <div  v-if="devices.length>0" class="mb-4">
        <label class="block text-sm font-bold mb-2">Select Devices</label>
        <div class="space-y-2">
          <div v-for="device in devices" :key="device.id" class="flex items-center">
            <input type="checkbox" :value="device.id" v-model="newAlert.devices" class="mr-2">
            <label>{{ device.name }}</label>
          </div>
        </div>
      </div>

        <div v-else-if="devices.length===0 && newAlert.deviceType" class="text-center text-xs text-red-500 mb-4">No devices found for selected device type</div>


        <div v-if="newAlert.deviceType" class="mb-4">
          <label class="block text-sm font-bold mb-2">Select Threshold Unit</label>
          <select v-model="newAlert.thresholdUnit" class="w-full p-2 border rounded">
            <option v-for="units in thresholdUnits" :key="units.id" :value="units.id">{{ units.name }}</option>
            <!-- Add more options as needed -->
          </select>
        </div>

        <div v-if="newAlert.thresholdUnit" class="mb-4">
          <label class="block text-sm font-bold mb-2">Threshold Value</label>
          <input type="number" v-model="newAlert.thresholdValue" class="w-full p-2 border rounded">
          <!-- adding i icon for selected thresholdUnit 'description' -->
          <i class="text-gray-500">{{ thresholdUnits.find(unit => unit.id === newAlert.thresholdUnit).description }}</i>
        </div>

        <div class="mb-4">
          <label class="block text-sm font-bold mb-2">Alert Type</label>
          <select v-model="newAlert.alertType" class="w-full p-2 border rounded">
            <option value="email">Email</option>
            <!-- Add more alert types as needed -->
          </select>
        </div>

<!--          level of the alert Warning/Critical-->
        <div class="mb-4">
          <label class="block text-sm font-bold mb-2">Level</label>
          <select v-model="newAlert.level" class="w-full p-2 border rounded">
            <option value="warning">Warning</option>
            <option value="critical">Critical</option>
          </select>
        </div>

        <button type="submit" class="w-full p-2 bg-blue-500 text-white font-bold rounded hover:bg-blue-600">Set Alert</button>
      </form>
    </div>
    <div class="w-2/3 bg-white p-4 rounded-lg shadow max-w-full overflow-x-auto">
      <h1 class="text-2xl font-bold text-center mb-4">Active Alerts</h1>
      <table v-if="alerts.length > 0" class="min-w-full leading-normal">
        <thead>
          <tr>
            <th class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
              Name
            </th>
            <th class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
              Description
            </th>
            <th class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
              Device Type
            </th>
            <th class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
              Unit
            </th>
            <th class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
              Threshold Value
            </th>
            <th class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
              Level
            </th>
            <th class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
              Status
            </th>
            <th class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
              Devices
            </th>
            <th class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
              Actions
            </th>
          </tr>
        </thead>
          <tbody>
            <tr v-for="alert in alerts" :key="alert.id" class="hover:bg-slate-100 bg-white cursor-pointer" @click="openModal(alert)">
              <td class="px-5 py-5 border-b border-gray-200 text-sm">
                {{ alert.name }}
              </td>
              <td class="px-5 py-5 border-b border-gray-200 text-sm">
                {{ alert.description }}
              </td>
              <td class="px-5 py-5 border-b border-gray-200 text-sm">
                {{ alert.device_type }}
              </td>
              <td class="px-5 py-5 border-b border-gray-200 text-sm">
                {{ alert.unit }}
              </td>
              <td class="px-5 py-5 border-b border-gray-200 text-sm">
                {{ alert.threshold_value }}
              </td>
              <td class="px-5 py-5 border-b border-gray-200 text-sm">
                {{ alert.level }}
              </td>
              <td class="px-5 py-5 border-b border-gray-200 text-sm">
                {{ alert.is_active ? 'Active' : 'Inactive' }}
              </td>
              <td class="px-5 py-5 border-b border-gray-200 text-sm">
                {{ alert.device_count }}
              </td>
              <td class="px-5 py-5 border-b border-gray-200 text-sm">
                <button @click.stop="deleteAlert(alert.id)" class="p-2 bg-red-500 text-white rounded">Delete alert</button>
              </td>
            </tr>
          </tbody>
        </table>
      <div v-else class="text-center text-gray-500">No active alerts</div>
  </div>

    </div>
    <AlertModal
      :isOpen="isModalOpen"
      :alert="selectedAlert"
      :onClose="closeModal"
    />

    <ViewTable
        :fetch-string="fetchString"
        />
  </div>

</template>

<script setup>
import { ref, onMounted } from 'vue';
import axios from 'axios';
import AlertModal from '../components/AlertModal.vue';
import ViewTable from "@/components/ViewTable.vue";

const alerts = ref([]);
const deviceTypes = ref([]);
const devices = ref([]);
const thresholdUnits = ref([]);
const fetchString = ref('logs/get_logs/?table=triggeredalert');


const newAlert = ref({
  type: '',
  deviceType: '',
  devices: [],
  thresholdUnit: '',
  thresholdValue: '',
  alertType: '',
  level: ''
});

const API_URL = process.env.VUE_APP_API_URL;
const isModalOpen = ref(false);
const selectedAlert = ref(null);

const openModal = (alert) => {
  selectedAlert.value = alert;
  isModalOpen.value = true;
};

const closeModal = () => {
  isModalOpen.value = false;
  selectedAlert.value = null;
};

const resetForm = () => {
  newAlert.value = {
    type: '',
    deviceType: newAlert.value.deviceType, // keep the device types when resetting the form
    devices: [],
    thresholdUnit: '',
    thresholdValue: '',
    alertType: '',
    level: ''
  };

  devices.value = [];
  thresholdUnits.value = [];

};

const fetchDeviceTypes = async () => {
  try {
    const response = await axios.get(`${API_URL}iotdata/get_device_types/`);
    console.log('Device Types:', response.data.name)
    deviceTypes.value = response.data;
  } catch (error) {
    console.error('Error fetching device types:', error);
  }
};

const fetchDevices = async () => {

  resetForm()

  try {
    // make sure form is empty before fetching new devices

    devices.value = [];
    newAlert.value.devices = [];


    const response = await axios.get(`${API_URL}iotdata/get_devices/`, {
        params: { device_type: newAlert.value.deviceType }
      }
    );

    fetchThresholdUnits();

    console.log('Devices:', response.data);
    devices.value = response.data;
  } catch (error) {
    console.error('Error fetching devices:', error);
  }
};

const fetchThresholdUnits = async () => {
  try {
    const response = await axios.get(`${API_URL}iotdata/get_threshold_units/`, {
        params: { device_type: newAlert.value.deviceType }
      }
    );
    console.log('Threshold Units:', response.data);
    thresholdUnits.value = response.data;
  } catch (error) {
    console.error('Error fetching threshold units:', error);
  }
};


const setAlert = async () => {
  try {
    const response = await axios.post(`${API_URL}iotdata/set_alert/`, newAlert.value);
    alerts.value.push(response.data);

    console.log('Setting alert:', newAlert.value);

    // Reset the form and fetch devices again
    fetchDevices()
    fetchAlerts()

  } catch (error) {
    console.error('Error setting alert:', error);
  }
};

const fetchAlerts = async () => {
  try {
    const response = await axios.get(`${API_URL}iotdata/get_user_alerts/`);
    alerts.value = response.data;
  } catch (error) {
    console.error('Error fetching alerts:', error);
  }

};

const deleteAlert = async (alert_id) => {
  try {
    await axios.delete(`${process.env.VUE_APP_API_URL}iotdata/delete_alert/${alert_id}/`);
    alerts.value = alerts.value.filter(alert => alert.id !== alert_id);
  } catch (error) {
    console.error('Error removing all devices:', error);
  }
};

onMounted(() => {
  fetchDeviceTypes();
  fetchAlerts();
});
</script>
