<template>
  <div v-if="isOpen" class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
    <div class="bg-white p-8 rounded shadow-md max-w-3xl w-full">
      <h2 class="text-2xl font-bold mb-4">Alert Details</h2>
      <div class="mb-4 ">
        <p><strong>Name:</strong> {{ localAlert.name }}</p>
        <p><strong>Description:</strong> {{ localAlert.description }}</p>
        <p><strong>Device Type:</strong> {{ localAlert.device_type }}</p>
        <p><strong>Unit:</strong> {{ localAlert.unit }}</p>
        <p><strong>Threshold Value:</strong> {{ localAlert.threshold_value }}</p>
        <p><strong>Level:</strong> {{ localAlert.level }}</p>
        <p><strong>Status:</strong> {{ localAlert.is_active ? 'Active' : 'Inactive' }}</p>
      </div>
      <h3 class="text-xl font-bold mb-2">Devices</h3>
      <table class="min-w-full leading-normal mb-4">
        <thead>
          <tr>
            <th class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Device ID</th>
            <th class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Description</th>
            <th class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Actions</th>
          </tr>
        </thead>
        <tbody class="text-start">
          <tr v-for="device in devices" :key="device.id" class="hover:bg-gray-100">
            <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">{{ device.name }}</td>
            <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm flex flex-row">
              <input v-model="device.description" class="border rounded p-1 w-full " />
              <button @click="saveDeviceDescriptions(device.device_id, device.description)" class="p-2 bg-blue-500 text-white rounded">Save</button>
            </td>
            <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
              <button @click="removeDevice(device.device_id)" class="text-red-500">Remove</button>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="flex justify-end space-x-2">
        <button @click="closeModal" class="p-2 bg-gray-500 text-white rounded">Close</button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, watch } from 'vue';
import axios from 'axios';

// Define props
const props = defineProps({
  isOpen: Boolean,
  alert: Object,
  onClose: Function,
});

// Create a local state for alert
const localAlert = ref({ ...props.alert });

// Define devices state
const devices = ref([]);

// Fetch devices for the alert
const fetchDevices = async (alertId) => {
  try {
    const response = await axios.get(`${process.env.VUE_APP_API_URL}iotdata/get_alert_devices/${alertId}/`);
    devices.value = response.data;
  } catch (error) {
    console.error('Error fetching devices:', error);
  }
};

// Watch for alert changes and fetch devices when the modal is opened
watch(
  () => props.alert,
  (newAlert) => {
    if (newAlert && props.isOpen) {
      localAlert.value = { ...newAlert };
      fetchDevices(newAlert.id);
    }
  }
);

// Close the modal
const closeModal = () => {
  devices.value = [];
  props.onClose();
};

// Save device descriptions
const saveDeviceDescriptions = async (device_id, description) => {
  try {
    await axios.put(`${process.env.VUE_APP_API_URL}iotdata/update_device_description/${props.alert.id}/${device_id}/`, {
      description: description,
    });
  } catch (error) {
    console.error('Error saving device description:', error);
  }
};

// Remove a specific device from the alert
const removeDevice = async (deviceId) => {
  try {
    await axios.delete(`${process.env.VUE_APP_API_URL}iotdata/remove_device_from_alert/${props.alert.id}/${deviceId}/`);
    devices.value = devices.value.filter((device) => device.id !== deviceId);
  } catch (error) {
    console.error('Error removing device:', error);
  }
};

</script>

<style scoped>
.fixed {
  position: fixed;
}
</style>
