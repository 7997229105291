<template>
  <div class="flex my-3 justify-center mx-auto md:text-md text-xs">
    <button
      @click="changePage(currentPage - 1)"
      :disabled="currentPage === 1"
      class="md:block hidden px-3 mx-1 border border-gray-300 rounded bg-white hover:bg-gray-100 disabled:opacity-50"
    >
      &lt;
    </button>
    <div>
      <button
        v-for="page in pages"
        :key="page"
        @click="changePage(page)"
        :class="[
          'md:px-3 px-1 py-2 mx-auto border border-gray-300 rounded',
          { 'bg-blue-500 text-white': currentPage === page, 'bg-white hover:bg-gray-100': currentPage !== page }
        ]"
      >
        {{ page }}
      </button>
    </div>

    <button
      @click="changePage(currentPage + 1)"
      :disabled="currentPage === totalPages"
      class="md:block hidden px-3 mx-1 border border-gray-300 rounded bg-white hover:bg-gray-100 disabled:opacity-50"
    >
      &gt;
    </button>
  </div>
</template>

<script setup>
import { computed } from 'vue';

const props = defineProps({
  totalItems: {
    type: Number,
    required: true
  },
  pageSize: {
    type: Number,
    default: 10
  },
  currentPage: {
    type: Number,
    required: true
  }
});

const emits = defineEmits(['page-change']);

const totalPages = computed(() => Math.ceil(props.totalItems / props.pageSize));

const pages = computed(() => {
  const range = [];
  const maxPagesToShow = 6;
  const currentPage = props.currentPage;
  const total = totalPages.value;

  if (total <= maxPagesToShow) {
    for (let i = 1; i <= total; i++) {
      range.push(i);
    }
  } else {
    range.push(1);
    if (currentPage > 3) {
      range.push('...');
    }
    const startPage = Math.max(2, currentPage - 1);
    const endPage = Math.min(total - 1, currentPage + 1);
    for (let i = startPage; i <= endPage; i++) {
      range.push(i);
    }
    if (currentPage < total - 2) {
      range.push('...');
    }
    range.push(total);
  }

  return range;
});

const changePage = (page) => {
  if (page === '...') return;
  if (page > 0 && page <= totalPages.value) {
    emits('page-change', page);
  }
};
</script>
