<template>
  <div id="map" ref="mapContainer" class="map-container"></div>
</template>

<script setup>
import { ref, watch, onMounted } from 'vue';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';

const props = defineProps({
  locationData: {
    type: Object,
    default: null,
  },
});

const mapContainer = ref(null);
const map = ref(null);

mapboxgl.accessToken = 'pk.eyJ1IjoiZGF3a2FtOTkxMiIsImEiOiJjbHowMHEyaW4xdXZ3MmpzMGZ4NjE4ZnE2In0.x75o2Xaioz2B7lXstGP9Zw';

const flyToStore = (store) => {
  const coordinates = [store.longitude, store.latitude];

  map.value.flyTo({
    center: coordinates,
    zoom: 17,
    pitch: 45,
    bearing: -17.6,
  });

  new mapboxgl.Popup()
    .setLngLat(coordinates)
    .setHTML(`<div class=""><h3 >${store.name}</h3><p>${store.region}</p></div>`)
    .addTo(map.value);
};

onMounted(() => {
  map.value = new mapboxgl.Map({
    container: mapContainer.value,
    style: 'mapbox://styles/mapbox/light-v11',
    center: [-0.1276, 51.5072], // Default center
    zoom: 10,
    pitch: 45,
    bearing: -17.6,
    antialias: true,
  });

  map.value.on('style.load', () => {
    // Insert the layer beneath any symbol layer.
    const layers = map.value.getStyle().layers;
    const labelLayerId = layers.find(
      (layer) => layer.type === 'symbol' && layer.layout['text-field']
    ).id;

    map.value.addLayer(
      {
        'id': 'add-3d-buildings',
        'source': 'composite',
        'source-layer': 'building',
        'filter': ['==', 'extrude', 'true'],
        'type': 'fill-extrusion',
        'minzoom': 15,
        'paint': {
          'fill-extrusion-color': '#aaa',
          'fill-extrusion-height': [
            'interpolate',
            ['linear'],
            ['zoom'],
            15,
            0,
            15.05,
            ['get', 'height']
          ],
          'fill-extrusion-base': [
            'interpolate',
            ['linear'],
            ['zoom'],
            15,
            0,
            15.05,
            ['get', 'min_height']
          ],
          'fill-extrusion-opacity': 0.6
        }
      },
      labelLayerId
    );
  });
});

watch(
  () => props.locationData,
  (newLocation) => {
    console.log('new location:', newLocation);
    if (newLocation && newLocation.latitude && newLocation.longitude) {
      flyToStore(newLocation);
    }
  },
  { immediate: true }
);
</script>

<style scoped>
.map-container {
  width: 100%;
  height: 100%;
}
</style>
