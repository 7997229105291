<template>
  <div>
    <canvas ref="canvas"></canvas>
  </div>
</template>

<script>
import { defineComponent, ref, watch, onMounted } from 'vue';
import { Chart as ChartJS, Title, Tooltip, Legend, LineElement, CategoryScale, LinearScale, PointElement, Filler, LineController } from 'chart.js';

ChartJS.register(Title, Tooltip, Legend, LineElement, CategoryScale, LinearScale, PointElement, Filler, LineController);

export default defineComponent({
  name: 'LineChart',
  props: {
    chartData: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    const canvas = ref(null);
    let chartInstance = null;

    const renderChart = () => {
      if (chartInstance) chartInstance.destroy();
      const ctx = canvas.value.getContext('2d');
      chartInstance = new ChartJS(ctx, {
        type: 'line',
        data: props.chartData,
        options: {
          responsive: true,
          maintainAspectRatio: false
        }
      });
    };

    watch(() => props.chartData, renderChart, { deep: true });

    onMounted(renderChart);

    return {
      canvas
    };
  }
});
</script>

<style scoped>
/* Add any additional styles for LineChart */
</style>
