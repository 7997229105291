// src/router/index.js
import { createRouter, createWebHistory } from 'vue-router';
// import HomePage from '../views/HomePage.vue';
import BIDashboard from '@/views/BIDashboard.vue';
import LoginPage from '@/views/LoginPage.vue';
import RegisterPage from "@/views/RegisterPage.vue";
import AlertPage from "@/views/AlertPage.vue";
import AccountActivation from "@/components/AccountActivation.vue";
import ForgotPassword from "@/components/ForgotPassword.vue";
import ResetPassword from "@/components/ResetPassword.vue";
import MasterData from "@/views/MasterData.vue";
import { jwtDecode } from 'jwt-decode';
import { refreshToken } from '@/services/auth';


// Define your routes array before using it to create the router instance
const routes = [
  {
    path: '',
    name: 'HomePage',
    component: BIDashboard,
    meta: { requiresAuth: true }
  },
  {
    path: '/dashboard',
    name: 'BIDashboard',
    component: BIDashboard,
    meta: { requiresAuth: true }
  },
  {
    path: '/login',
    name: 'LoginPage',
    component: LoginPage,
    meta: { public: true }  // Public route
  },
  {
    path: '/register',
    name: 'RegisterPage',
    component: RegisterPage,
    meta: { public: true }  // Public route
  },
  {
    path: '/alerts',
    name: 'Alerts',
    component: AlertPage,
    meta: { requiresAuth: true },
  },
  {
    path: '/activate/:token',
    name: 'AccountActivation',
    component: AccountActivation,
    meta: { public: true }  // Public route
  },
  { path: '/forgot-password', component: ForgotPassword,  meta: { public: true } },
  { path: '/reset-password', component: ResetPassword,  meta: { public: true } },
  { path: '/masterdata', component: MasterData, meta: { requiresAuth: true } },


];

// Now create the router using the routes array
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

// Navigation guard setup
router.beforeEach(async (to, from, next) => {
    const token = sessionStorage.getItem('token');

    if (to.matched.some(record => record.meta.public)) {
        next();
    } else {
        if (!token) {
            console.log('No token found, redirecting to login.');
            next({ name: 'LoginPage', query: { redirect: to.fullPath } });
        } else {
            try {
                const decoded = jwtDecode(token);
                const currentTime = Date.now() / 1000;

                if (decoded.exp && decoded.exp < currentTime) {
                    console.log('Token has expired');
                    sessionStorage.removeItem('token');
                    next({ name: 'LoginPage', query: { redirect: to.fullPath } });
                } else if (decoded.exp && decoded.exp - currentTime < 60 * 60 * 12) { // 12 hours before expiration -> refresh
                    console.log('Token is close to expiration, refreshing...');
                    const newToken = await refreshToken();
                    if (newToken) {
                        console.log('Token refreshed successfully.');
                        next();
                    } else {
                        console.log('Failed to refresh token, redirecting to login.');
                        next({ name: 'LoginPage', query: { redirect: to.fullPath } });
                    }
                } else {
                    console.log('Token is valid till: ', new Date(decoded.exp * 1000).toLocaleString());
                    next();
                }
            } catch (error) {
                console.log('Error decoding token:', error);
                sessionStorage.removeItem('token');
                next({ name: 'LoginPage', query: { redirect: to.fullPath } });
            }
        }
    }
});

export default router;