<template>
  <div class="flex w-full items-center justify-center min-h-screen bg-gray-100">
    <div class="max-w-md p-8 space-y-6 bg-white shadow-md rounded-lg">
      <h2 class="text-2xl font-bold text-center">Login</h2>
      <form @submit.prevent="handleSubmit" class="space-y-6">
        <div class="flex flex-col space-y-2">
          <div>
            <input
              type="email"
              id="email"
              placeholder="Email address"
              v-model="username"
              required
              class="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
            />
          </div>
          <div>
            <input
              type="password"
              id="password"
              placeholder="Password"
              v-model="password"
              required
              class="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
            />
          </div>
        </div>
        <div v-if="errorMessage" class="text-red-500 text-center">
          {{ errorMessage }}
        </div>
        <div>
          <button
            type="submit"
            class="w-full px-4 py-2 text-white bg-indigo-600 rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Login
          </button>
        </div>

        <div>
          <p class="text-sm text-center text-gray-600">
            Don't have an account? <router-link to="/register" class="text-indigo-600 hover:underline">Register</router-link>
          </p>

          <p class="text-sm text-center text-gray-600">
            Forgot your password? <router-link to="/forgot-password" class="text-indigo-600 hover:underline">Reset Password</router-link>
          </p>

        </div>
      </form>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import { login } from '../services/auth';
import { useRouter } from 'vue-router';

const router = useRouter();

const username = ref('');
const password = ref('');
const errorMessage = ref('');

const handleSubmit = async () => {
  errorMessage.value = ''; // Clear any previous error message
  try {
    const response = await login(username.value, password.value);
    sessionStorage.setItem('token', response.data.token); // Assuming 'token' is the key used by the backend
    console.log('Login successful');
    // Redirect to the original target or the home page
    const redirect = router.currentRoute.value.query.redirect || '/';
    router.push(redirect);
  } catch (error) {
    console.error('Login failed:', error);
    // Check if the server returned an error message and display it
    if (error.response && error.response.data && error.response.data.error) {
      errorMessage.value = error.response.data.error;
    } else {
      // Fallback error message if no specific error message is provided
      errorMessage.value = 'Login failed. Please try again or contact support.';
    }
  }
};
</script>


<style scoped>
/* Add any additional custom styles if needed */
</style>
