<template>
  <div class="flex flex-col w-full mx-auto p-8">
    <h1 class="text-4xl font-thin my-6 text-start">Master Data</h1>
    <div class="bg-white rounded-t-lg shadow space-x-4 flex justify-start md:text-lg text-md font-bold overflow-x-auto">
      <button
        id="locations"
        @click="setFetchString('locations')"
        :class="{'bg-slate-200 hover:bg-slate-200': activeButton === 'locations', 'bg-white': activeButton !== 'locations', 'hover:bg-slate-100': true}"
        class="p-3 m-1 rounded-lg"
      >
        Locations
      </button>
      <button
        id="device"
        @click="setFetchString('device')"
        :class="{'bg-slate-200 hover:bg-slate-200': activeButton === 'device', 'bg-white': activeButton !== 'device', 'hover:bg-slate-100': true}"
        class="p-3 m-1 rounded-lg"
      >
        Devices
      </button>
      <button
        id="devicetype"
        @click="setFetchString('devicetype')"
        :class="{'bg-slate-200 hover:bg-slate-200': activeButton === 'devicetype', 'bg-white': activeButton !== 'devicetype', 'hover:bg-slate-100': true}"
        class="p-3 m-1 rounded-lg"
      >
        Device Types
      </button>
<!--      <button-->
<!--        id="ambulances"-->
<!--        @click="setFetchString('ambulances')"-->
<!--        :class="{'bg-slate-200 hover:bg-slate-200': activeButton === 'ambulances', 'bg-white': activeButton !== 'ambulances', 'hover:bg-slate-100': true}"-->
<!--        class="p-3 m-1 rounded-lg"-->
<!--      >-->
<!--        Ambulances-->
<!--      </button>-->
      <button
        id="users"
        @click="setFetchString('customuser')"
        :class="{'bg-slate-200 hover:bg-slate-200': activeButton === 'customuser', 'bg-white': activeButton !== 'customuser', 'hover:bg-slate-100': true}"
        class="p-3 m-1 rounded-lg"
      >
        Users
      </button>
      <button
        id="locationcomponent"
        @click="setFetchString('locationcomponent')"
        :class="{'bg-slate-200 hover:bg-slate-200': activeButton === 'locationcomponent', 'bg-white': activeButton !== 'locationcomponent', 'hover:bg-slate-100': true}"
        class="p-3 m-1 rounded-lg"
      >
        Components
      </button>
    </div>

    <ViewTable
      :fetch-string="fetchString"
    />
  </div>
</template>

<script setup>
import { ref } from 'vue';
import ViewTable from "@/components/ViewTable.vue";

const fetchString = ref('masterdata/get_masterdata/?table=locations');
const activeButton = ref('locations');

function setFetchString(table) {
  fetchString.value = 'masterdata/get_masterdata/?table=' + table;
  activeButton.value = table;
}
</script>
