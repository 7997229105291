<template>
  <div class="flex flex-col">
    <!-- Sidebar (visible on screens >= sm) -->
    <div class="xl:w-32 w-16"></div>
    <aside :class="`sm:block hidden h-screen fixed bg-gray-900 text-white flex flex-col z-20 transition-all duration-300 ${is_expanded ? 'relative w-64' : 'xl:w-32 sm:w-16'}`">
      <div class="flex flex-col h-full justify-between">
        <div class="flex flex-col">
          <div class="flex items-center justify-center my-10 mx-auto">
            <img :src="logoURL" alt="Logo" class="h-12 w-12 my-12" v-if="!is_expanded" />
            <div :class="`mt-10 mb-4 ${is_expanded ? 'block' : 'hidden'}`">
              <img :src="LogoNew" alt="Logo 2" class="h-24 w-auto" />
            </div>
          </div>
        </div>
        <div class="flex flex-col mx-auto text-3xl flex-1">

          <router-link to="/dashboard" :class="is_expanded ? 'justify-start' : 'justify-center'" class="flex items-center rounded-3xl px  p-2 transition-colors duration-200 hover:bg-gray-700">
            <img :src="HomeLogo" alt="Home" class="h-12 w-12 object-contain" />
            <span :class="`ml-4 ${is_expanded ? 'block' : 'hidden'} opacity-100 transition-opacity duration-300`">Home</span>
          </router-link>

          <router-link to="/alerts" :class="is_expanded ? 'justify-start' : 'justify-center'" class="pointer-events-none flex items-center rounded-3xl p-2 transition-colors duration-200 hover:bg-gray-700">
            <img :src="NotificationLogo" alt="Notifications" class="h-12 w-12 object-contain" />
            <span :class="`ml-4 ${is_expanded ? 'block' : 'hidden'} opacity-100 transition-opacity duration-300`">Alerts</span>
          </router-link>

          <router-link to="/masterdata" :class="is_expanded ? 'justify-start' : 'justify-center'" class="flex items-center rounded-3xl p-2 transition-colors duration-200 hover:bg-gray-700">
            <img :src="DatabaseLogo" alt="Database" class="h-12 w-12 object-contain" />
            <span :class="`ml-4 ${is_expanded ? 'block' : 'hidden'} opacity-100 transition-opacity duration-300`">CMS</span>
          </router-link>

          <router-link to="/settings" :class="is_expanded ? 'justify-start' : 'justify-center'" class="pointer-events-none flex items-center rounded-3xl p-2 transition-colors duration-200 hover:bg-gray-700">
            <img :src="SettingsLogo" alt="Settings" class="h-12 w-12 object-contain" />
            <span :class="`ml-4 ${is_expanded ? 'block' : 'hidden'} opacity-100 transition-opacity duration-300`">Settings</span>
          </router-link>

        </div>
        <div class="flex items-end justify-center mb-4 mr-4">
          <button @click="ToggleMenu" class="h-12 w-12 transform transition-transform duration-200 hover:bg-gray-700 rounded-3xl">
            <img :src="DoubleArrow" alt="Toggle Menu" :class="is_expanded ? 'rotate-180' : 'rotate-0'" />
          </button>
        </div>
      </div>
    </aside>

    <!-- Navbar with Eyya Logo and Donut Button (visible on screens < sm) -->
    <nav class="w-full bg-gray-900 text-white flex items-center justify-between h-16 sm:hidden relative">
      <!-- Eyya Logo -->
      <div class="flex items-center px-4 ">
        <img :src="logoURL" alt="Eyya Logo" class="h-12 w-auto" />
      </div>

      <!-- Donut Button -->
      <div class="relative">
        <button @click="toggleDropdown" class="focus:outline-none bg-gray-900 rounded-full flex items-center justify-center px-4">
          <img :src="MenuIcon" alt="Menu" class="h-12 w-12 text-white" />
        </button>

        <!-- Dropdown Menu -->
        <div v-show="is_dropdown_open" class="absolute right-0 mt-2 w-48 z-50 bg-gray-900 text-white shadow-lg">
          <router-link @click="toggleDropdown" to="/dashboard" class="block py-2 px-4 hover:bg-gray-700">Home</router-link>
          <router-link @click="toggleDropdown" to="/alerts" class="block py-2 px-4 hover:bg-gray-700">Alerts</router-link>
          <router-link @click="toggleDropdown" to="/masterdata" class="block py-2 px-4 hover:bg-gray-700">CMS</router-link>
          <router-link @click="toggleDropdown" to="/settings" class="block py-2 px-4 hover:bg-gray-700">Settings</router-link>
          <router-link @click="toggleDropdown" to="/login" class="block py-2 px-4 hover:bg-gray-700">Logout</router-link>
        </div>
      </div>
    </nav>

    <!-- Overlay for Sidebar when expanded -->
    <div v-show="is_expanded" @click="ToggleMenu" class="fixed inset-0 sm:block hidden bg-black bg-opacity-70 z-10"></div>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import logoURL from '../assets/images/Logo.png';
import LogoNew from '../assets/images/Logo2.png';
import HomeLogo from '../assets/images/home.png';
import NotificationLogo from '../assets/images/notifications.png';
import DatabaseLogo from '../assets/images/database.png';
import SettingsLogo from '../assets/images/settings.png';
import DoubleArrow from '../assets/images/double_arrow.png';
import MenuIcon from '../assets/images/menu.png';

const is_expanded = ref(localStorage.getItem('is_expanded') === 'true');
const is_dropdown_open = ref(false);

const toggleDropdown = () => {
  is_dropdown_open.value = !is_dropdown_open.value;
};

const ToggleMenu = () => {
  is_expanded.value = !is_expanded.value;
  localStorage.setItem('is_expanded', is_expanded.value);
};
</script>

