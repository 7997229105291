import { createApp } from 'vue'
import App from './App.vue'
import './assets/tailwind.css'
import router from './router';
import axios from 'axios';

axios.defaults.baseURL = process.env.VUE_APP_API_URL;

createApp(App).use(router).mount('#app');

axios.interceptors.request.use(function(config) {
  const token = sessionStorage.getItem('token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
}, function(error) {
  return Promise.reject(error);
});

axios.interceptors.response.use(response => response, async error => {
    const originalRequest = error.config;
    // Check if it's not a retry and not a login request
    if (error.response.status === 401 && !originalRequest._retry && !originalRequest.skipRefresh) {
        if (error.response.data.error === 'token_expired') {
            originalRequest._retry = true;
            try {
                const refreshToken = sessionStorage.getItem('refresh_token');
                const API_URL = process.env.VUE_APP_API_URL;
                const refreshTokenResponse = await axios.post(`${API_URL}api/token/refresh/`, {
                    refresh_token: refreshToken
                });
                sessionStorage.setItem('access_token', refreshTokenResponse.data.access_token);
                axios.defaults.headers.common['Authorization'] = `Bearer ${refreshTokenResponse.data.access_token}`;
                originalRequest.headers['Authorization'] = `Bearer ${refreshTokenResponse.data.access_token}`;
                return axios(originalRequest);
            } catch (refreshError) {
                console.error('Token refresh failed:', refreshError);
                return Promise.reject(refreshError);
            }
        } else {
            // Handle other types of 401 errors, such as invalid credentials during login
            console.error('Authentication failed:', error.response.data.message);
        }
    }
    return Promise.reject(error);
});

